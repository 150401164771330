// @file Vuex plugin that log mutations in Chrome console.

/* eslint-disable no-console */
import { isDebugMode } from '@@/bits/flip'
import type { Store } from 'vuex'

export const shouldLog = isDebugMode

export default (store: Store<any>): void => {
  store.subscribe((mutation, state): void => {
    const d = new Date()
    const pad = (num: number, size: number): string => {
      let s: string
      s = num + ''
      while (s.length < size) {
        s = '0' + s
      }
      return s
    }

    const formattedDate =
      pad(d.getHours(), 2) +
      ':' +
      pad(d.getMinutes(), 2) +
      ':' +
      pad(d.getSeconds(), 2) +
      '.' +
      pad(d.getMilliseconds(), 3)

    // cloneDeep(store.state) is problematic (e.g. on map format, the store contains a Google Map object with some reference that causes stack overflow)
    // and a log of the whole state can be too much to be used for debugging
    // => remove it for now
    console.groupCollapsed('%cmutate @', 'color: #18b11e; font-weight: bold', formattedDate, mutation.type)
    // console.log('%c prev state', 'color: blue; font-weight: bold', cloneDeep(store.state)) // goodcheck-disable-line
    console.log('%c action', 'color: black; font-weight: bold', mutation) // goodcheck-disable-line
    // console.log('%c current state', 'color: green; font-weight: bold', cloneDeep(state)) // goodcheck-disable-line
    console.groupCollapsed('%c trace', 'color: red; font-weight: bold')
    console.log(new Error('Trace using Error.stack').stack) // goodcheck-disable-line
    console.groupCollapsed('Trace using console.trace')
    console.trace()
    console.groupEnd()
    console.groupEnd()
    console.groupEnd()
  })
}
